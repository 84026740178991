import React from "react"
import { Link } from "gatsby"
import { Trans } from "gatsby-plugin-react-i18next"
import { IoClose } from "react-icons/io5"
const Modal = ({ children, title, link }) => {
  return (
    <div className="modal fade" id="exampleModal" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header bg-dark text-light">
            <h5 className="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <button
              type="button"
              className="btn btn-outline-warning"
              data-bs-dismiss="modal"
            >
              <IoClose />
            </button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-dark"
              data-bs-dismiss="modal"
            >
              <Trans>Close</Trans>
            </button>
            <Link to={link}>
              <button type="button" className="btn btn-warning">
                <Trans>Show Example</Trans>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
